import cinematicVideo from "../media/video.mp4";

function Video() {
  return (
    <video autoPlay loop muted className="absolute z-0 w-auto min-w-full min-h-full max-w-none">
      <source src={cinematicVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}

export default Video;
