import logo from "../media/logo.png";

function Logo() {
  return (
    <div className="absolute flex items-center justify-center h-screen w-screen mb-12 z-50 overflow-hidden">
      <div className="w-screen z-50 h-screen flex flex-col justify-center items-center">
        <div className="absolute flex justify-center items-center z-50 p-5 text-2xl text-white bg-purple-300 bg-opacity-50 rounded-xl">
          <a href="https://forum.ybn-ls.io" className="cursor-pointer">
            <div className="relative flex h-48 w-48 md:h-64 md:w-64 lg:h-96 lg:w-96 transform transition duration-150 ease-in-out hover:scale-105">
              <div className="z-10 absolute w-full h-full flex justify-center items-center bg-gradient-to-br from-gray-900 to-black">
                <h1 className="-mt-2 -ml-4 font-bold text-transparent bg-clip-text text-8xl w-full h-full">
                  <span className="inline-flex justify-center items-center w-full h-full ml-2.5">
                    <img src={logo} className="w-48 md:w-64 lg:w-96 h-auto" alt="Logo" />
                  </span>
                </h1>
              </div>
              <div className="absolute w-full h-full bg-conic-gradient filter blur-xl"></div>
              <div className="absolute w-full h-full bg-conic-gradient filter blur-3xl opacity-60 animate-pulse"></div>
              <div className="absolute -inset-0.5 rounded-sm bg-conic-gradient"></div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Logo;
