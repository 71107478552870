import Logo from "./components/Logo";
import Footer from "./components/Footer";
import Video from "./components/Video";
import Alert from "./components/Alert";

function App() {
  return (
    <>
      <Video />
      <Logo />
      <Footer />
      <Alert />
    </>
  );
}

export default App;
