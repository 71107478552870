import React, { useState, useEffect } from "react";

const Alert = () => {
  const [interval, setIntervalState] = useState(null);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    setIntervalState(
      setInterval(() => {
        setShowAlert(false);
      }, 30000)
    );

    return () => {
      if (interval != null) return clearInterval(interval);
    };
  }, []);

  return (
    <>
      {showAlert ? (
        <div className="top-0 right-0 fixed z-50 w-full md:max-w-sm p-4 md:p-4 max-h-screen overflow-hidden pointer-events-none">
          <div className="flex-1 flex-col fade w-full mr-8 justify-end pointer-events-none">
            <div className="flex py-1 w-full transform transition-all duration-300 pointer-events-auto">
              <div className="flex w-full visible flex-row shadow-lg border-l-4 rounded-md duration-100 cursor-pointer transform transition-all hover:scale-102 bg-black opacity-95 border-blue-500 max-h-40">
                <div className="flex flex-row p-2 flex-no-wrap w-full">
                  <div className="flex items-center h-12 w-12 mx-auto text-xl select-none">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="info-circle"
                      className="svg-inline--fa fa-info-circle fa-w-16 w-4 h-4 mx-auto text-blue-500"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                      ></path>
                    </svg>
                  </div>
                  <div className="flex flex-col flex-no-wrap px-1 w-full">
                    <div className="flex my-auto font-bold select-none text-white">Info</div>
                    <p className="-mt-0.5 my-auto break-wrap flex text-gray-400 text-sm truncate-1-lines">You can click the logo to visit the forums.</p>
                  </div>
                  <div className="w-10 h-12 mr-2 items-center mx-auto text-center leading-none text-lg">
                    <svg
                      onClick={() => setShowAlert(false)}
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="times"
                      className="svg-inline--fa fa-times fa-w-11 w-4 h-4 mx-auto my-auto text-center text-white cursor-pointer hover:scale-105 transform"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 352 512"
                    >
                      <path
                        fill="currentColor"
                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default function closeAlert() {
  return (
    <>
      return <Alert />;
    </>
  );
}
